<template>
  <div  class="sidebar-panel">
    <div class="gull-brand text-center p-4 d-flex justify-content-center align-items-center">
      <a>
        <!-- <i class="fas fa-home text-white fa-2x"></i> -->
         <img class="bg-white rounded" :src="`${publicPath}assets/images/logo.png`" width="100">
      </a>
      <div class="sidebar-compact-switch ml-auto"><span></span></div>
    </div>
    
    <div class="scroll-nav">
      <div class="side-nav">

        <div id="sidebarMenu" class="main-menu">

          <ul class="metismenu" id="menu">
            
                <li class="Ul_li--hover mm-actives">
                  <a href="/"><i class="i-Bar-Chart text-20 mr-2 text-muted"></i><span class="item-name text-15 text-muted">{{$t('dashboard.text')}}</span></a>
                </li>

<!--                <li class="Ul_li&#45;&#45;hover mm-actives">-->
<!--                  <router-link :to="{name: 'ManagingWorkTeam'}" class="link" >-->
<!--                    <i class="fa-solid fa-people-group text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('managingWorkTeam.text') }} </span>-->
<!--                  </router-link>-->
<!--                </li>-->
          
<!--                <li class="Ul_li&#45;&#45;hover mm-actives">-->
<!--                  <router-link :to="{name: 'WorkTeam'}" class="link" > -->
<!--                    <i class="fa-solid fa-people-carry-box text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('workTeam.text') }} </span>-->
<!--                  </router-link>-->
<!--                </li>-->
                <!-- <li class="Ul_li--hover mm-actives" v-for="(tab,inex) in menuTabs" :key="index">
                  <router-link :to="{name: tab.path}" class="link" :id="inex">
                    <i :class="tab.icon" ></i> <span class="item-name text-15 text-muted"> {{ tab.name }} </span>
                  </router-link>
                </li> -->
                <li v-if="checkPermissionLink('groups') || checkPermissionLink('departments') " class="Ul_li--hover ul_li-large mm-actives">
                  <router-link :to="{name: 'ConfigureDepartments' , params: {schoolName: schoolName}}" class="link" >
                    <i class="fa-solid fa-users-rectangle text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('configureSectionsAndGroups.text') }} </span>
                  </router-link>
                </li>
                <li v-if="checkPermissionLink('teams')" class="Ul_li--hover mm-actives">
                  <router-link :to="{name: 'ManagingWorkTeam' , params: {schoolName: schoolName}}" class="link" >
                    <i class="fa-solid fa-people-group text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('managingWorkTeam.text') }} </span>
                  </router-link>
                </li>

                <li v-if="checkPermissionLink('role') || checkPermissionLink('users')" class="Ul_li--hover mm-actives">
                  <router-link :to="{name: 'PermissionManagement' , params: {schoolName: schoolName}}" class="link" > 
                    <i class="fa-solid fa-users-rays text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('userMangment.text') }} </span>
                  </router-link>
                </li>
               
                <li class="Ul_li--hover">
                  <a class="has-arrow" href="#">
                    <i class="fab fa-buromobelexperte text-20 mr-2 text-muted"></i>
                    <span class="item-name text-15 text-muted">{{ $t('UQOptions.text') }}</span>
                  </a>

                  <ul class="mm-collapse">
                    <li v-if="checkPermissionLink('templates')" class="item-name text-initial"  >
                      <router-link :to="{name: 'OriginalSettings', params: {schoolName: schoolName}}" class="link" >
                      <i class="fa-solid fa-money-check-dollar mr-2"></i> <span class="text-muted"> {{ $t('MCQsettingsModels.text') }} </span>
                      </router-link>
                    </li>
                    <li v-if="checkPermissionLink('questions')" class="item-name text-initial"  >
                      <router-link :to="{name: 'QuestionList', params: {schoolName: schoolName}}" class="link" >
                        <i class="fa-brands fa-product-hunt mr-2"></i> <span class="text-muted"> {{ $t('questions.text') }} </span>
                      </router-link>
                    </li>
                    <li v-if="checkPermissionLink('baskets')" class="item-name text-initial">
                  <router-link :to="{name: 'QuestionsBasket', params: {schoolName: schoolName}}" class="link" >
                    <i class="fa-solid fa-cart-plus mr-2"></i> <span class="text-muted"> {{ $t('questionsBasket.text') }} </span>
                  </router-link>
                </li>
                <!---<li class="item-name text-initial"> 
                  <router-link :to="{name: 'QuestionsTemplates'}" class="link" >
                    <i class="fa-solid fa-gopuram mr-2"></i> <span class="text-muted"> {{ $t('questionsTemplates.text') }} </span>
                  </router-link>
                </li>-->
                <li v-if="checkPermissionLink('exams')" class="item-name text-initial"  >
                      <router-link :to="{name: 'MCQExams', params: {schoolName: schoolName}}" class="link" >
                      <i class="fa-solid fa-solid fa-clipboard-check mr-2"></i> <span class="text-muted"> {{ $t('MCQExams.text') }} </span>
                      </router-link>
                    </li>

                  </ul>
                </li>
                <li v-if="checkPermissionLink('topics') || checkPermissionLink('full_exams') " class="Ul_li--hover">
                  <a class="has-arrow" href="#">
                    <i class="fab fa-unity text-20 mr-2 text-muted"></i>
                    <span class="item-name text-15 text-muted">{{ $t('examsUnits.text') }}</span>
                  </a>

                  <ul class="mm-collapse">
                    <li v-if="checkPermissionLink('ex_templates')"  class="item-name text-initial"  >
                      <router-link :to="{name: 'ExamModels', params: {schoolName: schoolName}}" class="link" >
                        <i class="fab fa-unity mr-2"></i> <span class="text-muted"> {{ $t('model.text') }} </span>
                      </router-link>
                    </li>
                    <li v-if="checkPermissionLink('topics')" class="item-name text-initial"  >
                      <router-link :to="{name: 'ModelTopics', params: {schoolName: schoolName}}" class="link" >
                        <i class="fa-solid fa-list mr-2"></i> <span class="text-muted"> {{ $t('topic.text') }} </span>
                      </router-link>
                    </li>
                    <li v-if="checkPermissionLink('full_exams')" class="item-name text-initial"  >
                      <router-link :to="{name: 'Exams', params: {schoolName: schoolName}}" class="link" >
                        <i class="fa-solid fa-list mr-2"></i> <span class="text-muted"> {{ $t('exams.text') }} </span>
                      </router-link>
                    </li>

                  </ul>
                </li>
                <li class="Ul_li--hover">
                  <a class="has-arrow" href="#">
                    <i class="fab fa-unity text-20 mr-2 text-muted"></i>
                    <span class="item-name text-15 text-muted">{{ $t('exerciseUnits.text') }}</span>
                  </a>

                  <ul class="mm-collapse">

                    <li class="item-name text-initial"  >
                      <router-link v-if="checkPermissionLink('ex_templates')"  :to="{name: 'ExerciseTemplate', params: {schoolName: schoolName}}" class="link" >
                        <i class="fab fa-unity mr-2"></i> <span class="text-muted"> {{ $t('exerciseTemplate.text') }} </span>
                      </router-link>
                    </li>

                    <li class="item-name text-initial"  >
                      <router-link v-if="checkPermissionLink('topics')"  :to="{name: 'Exercises', params: {schoolName: schoolName}}" class="link" >
                        <i class="fa-solid fa-list mr-2"></i> <span class="text-muted"> {{ $t('TheExercises.text') }} </span>
                      </router-link>
                    </li>

                  </ul>
                </li>

                <li v-if="checkPermissionLink('invoices') || checkPermissionLink('subscribers')"  class="Ul_li--hover ul_li-large mm-actives">
                  <a class="has-arrow link" href="#">
                    <i class="fas fa-wrench text-20 mr-2 text-muted"></i>
                    <span class="item-name text-15 text-muted">{{ $t('salesAndSubscriptions.text') }} </span>
                  </a>

                  <ul class="mm-collapse">
                    <li v-if="checkPermissionLink('subscribers')" class="item-name text-initial"  >
                      <router-link :to="{name: 'Subscriptions', params: {schoolName: schoolName}}" class="link" >
                        <i class="fas fa-code-branch mr-2"></i> <span class="text-muted"> {{ $t('subscriptions.text') }} </span>
                      </router-link>
                    </li>
                    <li v-if="checkPermissionLink('invoices')" class="item-name text-initial"  >
                      <router-link :to="{name: 'Sales', params: {schoolName: schoolName}}" class="link" >
                        <i class="fas fa-shopping-basket mr-2"></i> <span class="text-muted"> {{ $t('sales.text') }} </span>
                      </router-link>
                    </li>

                  </ul>
                </li>
                <li v-if="checkPermissionLink('library_types') || checkPermissionLink('library_extensions') || checkPermissionLink('libraries')"  class="Ul_li--hover">
                  <a class="has-arrow" href="#">
                    <i class="fa-solid fa-book-open text-20 mr-2 text-muted"></i>
                    <span class="item-name text-15 text-muted">{{ $t('libraries.text') }}</span>
                  </a>

                  <ul class="mm-collapse">
                    <li v-if="checkPermissionLink('library_types')" class="item-name text-initial"  >
                      <router-link :to="{name: 'TypesOfFiles', params: {schoolName: schoolName}}" class="link" >
                        <i class="fa-solid fa-book mr-2"></i><span class="text-muted"> {{ $t('typesOfFiles.text') }} </span>
                      </router-link>
                    </li>
                    <li v-if="checkPermissionLink('library_extensions')" class="item-name text-initial"  >
                      <router-link :to="{name: 'ExtensionsOfFiles', params: {schoolName: schoolName}}" class="link" >
                        <i class="fa-solid fa-book-open-reader mr-2"></i><span class="text-muted"> {{ $t('extensionsOfFiles.text') }} </span>
                      </router-link>
                    </li>
                    <li v-if="checkPermissionLink('libraries')"  class="item-name text-initial"  >
                      <router-link :to="{name: 'Files', params: {schoolName: schoolName}}" class="link" >
                        <i class="fa-regular fa-file mr-2"></i> <span class="text-muted"> {{ $t('files.text') }} </span>
                      </router-link>
                    </li>

                  </ul>
                </li>

                <li v-if="checkPermissionLink('clients') || checkPermissionLink('promo_codes')"  class="Ul_li--hover ul_li-large mm-actives">
                      <router-link :to="{name: 'PromoCodesAndPartners', params: {schoolName: schoolName}}" class="link" >
                      <i class="fa-solid text-20 fa-handshake mr-2"></i> 
                      <span class="item-name text-15 text-muted"> {{ $t('promoCodesAndPartners.text') }} </span>
                      </router-link>
                    </li>

                    <li v-if="checkPermissionLink('tracks')"  class="Ul_li--hover ul_li-large mm-actives">
                      <router-link :to="{name: 'Tracks', params: {schoolName: schoolName}}" class="link" >
                      <i class="fa-solid fa-boxes-stacked mr-2"></i>
                      <span class="item-name text-15 text-muted"> {{ $t('tracks.text') }} </span>
                      </router-link>
                    </li>

                    <li v-if="checkPermissionLink('ads')" class="Ul_li--hover ul_li-large mm-actives">
                      <router-link :to="{name: 'Ads', params: {schoolName: schoolName}}" class="link" >
                      <i class="fa-brands fa-adversal mr-2  text-20 "></i> 
                      <span class="item-name text-15 text-muted"> {{ $t('ads.text') }} </span>
                      </router-link>
                    </li>
                    <li v-if="checkPermissionLink('report_users') || checkPermissionLink('reports')" class="Ul_li--hover ul_li-large mm-actives">
                      <router-link :to="{name: 'SystemReports', params: {schoolName: schoolName}}" class="link" >
                      <i class="fa-solid fa-bug mr-2 text-20"></i>
                      <span class="item-name text-15 text-muted"> {{ $t('systemReports.text') }} </span>
                      </router-link>
                    </li>
                    <li v-if="checkPermissionLink('feedback_user_exams') || checkPermissionLink('feedback_exam_comments') || checkPermissionLink('feedback')" class="Ul_li--hover ul_li-large mm-actives">
                      <router-link :to="{name: 'Feedbacks', params: {schoolName: schoolName}}" class="link" >
                      <i class="fa-solid fa-comment-dots mr-2 text-20"></i>
                      <span class="item-name text-15 text-muted"> {{ $t('feedbacks.text') }} </span>
                      </router-link>
                    </li>
                    <li v-if="checkPermissionLink('lives')" class="Ul_li--hover ul_li-large mm-actives">
                      <router-link :to="{name: 'Lives', params: {schoolName: schoolName}}" class="link" >
                      <i class="fa-solid fa-users-line mr-2 text-20"></i>
                      <span class="item-name text-15 text-muted"> {{ $t('lives.text') }} </span>
                      </router-link>
                    </li>
<!--                <li class="Ul_li&#45;&#45;hover mm-actives">-->
<!--                  <router-link :to="{name: 'ManagePricesPackages'}" class="link" >-->
<!--                    <i class="fa-solid fa-people-group text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('managePricesPackages.text') }} </span>-->
<!--                  </router-link>-->
<!--                </li>-->

                <li v-if="checkPermissionLink('settings')" class="Ul_li--hover mm-actives">
                  <router-link :to="{name: 'settings', params: {schoolName: schoolName}}" class="link" >
                    <i class="fa-solid fa-cog text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('platformSettings.text') }} </span>
                  </router-link>
                </li>

                <li class="Ul_li--hover last">
                  <router-link :to="{name: 'organizationalChart', params: {schoolName: schoolName}}" class="without-after-element">
                    <i class="fab fa-pagelines text-20 mr-2 text-muted"></i>
                    <span class="item-name text-15 text-muted">{{ $t('basicData.text') }}</span>
                  </router-link>
            </li>
            <li class="Ul_li--hover mm-actives ul_li-large">
              <a @click.prevent="redirectUrl()" target="_blank">
                <i class="fa-solid fas fa-list text-20 mr-2 text-muted"></i>
                <span class="item-name text-15 text-muted"> {{ $t('discourse.text') }} </span>
              </a>
            </li>

          </ul>


        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "app-sidebar",
  data() {
    return {
      currentSidebar : '',
      pages: [],
      Path: process.env.VUE_APP_API,
      publicPath: process.env.BASE_URL,
      schoolName: '',
      menuIcons: ["i-Bar-Chart", "i-Library", "i-Suitcase", "i-Computer-Secure", "i-Computer-Secure", "i-File-Clipboard-File--Text", "i-File-Clipboard-File--Text", "i-File-Horizontal-Text", "i-Double-Tap", "i-Safe-Box1", "i-Bar-Chart", "i-File-Horizontal-Text", "i-Library",],
      loading: false,
      // menuTabs:[
      //   {
      //     path:"home",
      //     name:this.$t('dashboard.text'),
      //     icon:"i-Bar-Chart text-20 mr-2 text-muted"
      //   },
      //   {
      //     path:"ManagingWorkTeam",
      //     name:this.$t('managingWorkTeam.text'),
      //     icon:"fa-solid fa-people-group text-20 mr-2 text-muted"
      //   },
      //   {
      //     path:"WorkTeam",
      //     name:this.$t('workTeam.text'),
      //     icon:"fa-solid fa-people-carry-box text-20 mr-2 text-muted"
      //   },
      // ]
    }
  },
  created() {
    this.schoolName = localStorage.getItem('schoolName');
  },
  mounted() {
    /////////////////////////////////////
    
    /////////////////////////////////////
  },
  methods: {
    redirectUrl() {
      window.location.href = this.Path +"discourse/user/"+ localStorage.getItem('token')
    },
  },
  computed: {
    changeMenu() {
      return this.$store.state.dashboardData.leftmenuScroller == 'e';
    },

  },
  watch:{
    '$i18n.locale': function() {
    }
  }
}
</script>

<style scoped>
.link {
  opacity: .8;
  font-size: 14px;
}
.router-link-active {
  opacity: 1;
}
.gull-brand img {
  background: #fff;
  padding: 5px;
}
[dir="rtl"] .metismenu .has-arrow:after {
  left: 5px !important;
  right: auto !important;
}
.metismenu .has-arrow:after {
  right: 5px !important;
}
[dir="rtl"] .sidebar-compact-switch.ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}
</style>
