import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import StudentView from '../views/StudentView.vue'
import axios from "axios";

const routes = [
  {
    path: '/:schoolName',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: 'invalid-license',
        name: 'InvalidLicense',
        mete:{title:'Invalid License'},
        component: () => import ('../components/License/InvalidLicense')
      },
      {
        path: 'expired-license',
        name: 'ExpiredLicense',
        mete:{title:'Expired License'},
        component: () => import ('../components/License/InvalidLicense')
      },
      {
        path: 'invalid-url',
        name: 'InvalidUrl',
        mete:{title:'Invalid Url'},
        component: () => import ('../components/License/InvalidLicense')
      },
      {
        path: 'profile',
        name: 'ProfileUpdate',
        role: ['admin'],
        component: () => import ('../components/Profile/Profile.vue'),
      },
      {
        path: 'test-show-page',
        name: 'testShow',
        component: () => import('../components/BasicData/typesOfWorkTeams/show.vue')
      },
      {
        path: 'sales',
        name: 'Sales',
        meta: { role: ['admin'] , permission: 'invoices' , type: 'parent' },
        component: () => import('../components/Sales/List.vue'),
        children: [
          {
            path: 'create',
            name: 'SalesCreate',
            meta: { role: ['admin'] , permission: 'invoices_create' , type: 'child' },
            component: () => import('../components/Sales/Create.vue')
          }, {
            path: 'update',
            name: 'SalesUpdate',
            meta: { role: ['admin'] , permission: 'invoices_edit' , type: 'child' },
            component: () => import('../components/Sales/Update.vue')
          }, {
            path: 'show',
            name: 'SalesShow',
            meta: { role: ['admin'] , permission: 'invoices_read' , type: 'child' },
            component: () => import('../components/Sales/Show')
          }
        ]
      },
      {
        path: 'subscriptions',
        name: 'Subscriptions',
        meta: {role: ['admin'], permission: 'subscribers'  , type : 'parent'},
        component: () => import('../components/Subscriptions/List.vue'),
        children: [
          {
            path: 'create',
            name: 'SubscriptionsCreate',
            meta: {role: ['admin'], permission: 'subscribers_create'  , type : 'child'},
            component: () => import('../components/Subscriptions/Create.vue')
          }, {
            path: 'update',
            name: 'SubscriptionsUpdate',
            meta: {role: ['admin'], permission: 'subscribers_edit'  , type : 'child'},
            component: () => import('../components/Subscriptions/Update.vue')
          }, {
            path: 'show',
            name: 'SubscriptionsShow',
            meta: {role: ['admin'], permission: 'subscribers_read'  , type : 'child'},
            component: () => import('../components/Subscriptions/Show.vue')
          }
        ]
      },
      {
        path: 'countries',
        name: 'Countries',
        meta: {role: ['admin']},
        component: () => import('../components/Countries/List.vue'),
        children: [
          {
            path: 'create',
            name: 'CountriesCreate',
            meta: {role: ['admin']},
            component: () => import('../components/Countries/Create.vue')
          }, {
            path: 'update',
            name: 'CountriesUpdate',
            meta: {role: ['admin']},
            component: () => import('../components/Countries/Update.vue')
          }, {
            path: 'show',
            name: 'CountriesShow',
            meta: {role: ['admin']},
            component: () => import('../components/Countries/Show.vue')
          }
        ]
      },
      {
        path: 'model-topics',
        name: 'ModelTopics',
        meta: {role: ['admin'], permission: 'topics'  , type : 'parent'},
        component: () => import('../components/ModelTopics/List.vue'),
        children: [
          {
            path: 'create',
            name: 'TopicsCreate',
            meta: {role: ['admin'], permission: 'topics_create'  , type : 'child'},
            component: () => import('../components/ModelTopics/Create.vue')
          },
          {
            path: 'update',
            name: 'TopicsUpdate',
            component: () => import('../components/ModelTopics/Update.vue'),
          },
        ]
      },
      {
        path: 'promo-codes-partners',
        name: 'PromoCodesAndPartners',
        meta: {role: ['admin'],type: 'none'},
        component: () => import('../components/PromoCodesAndPartners/List.vue'),
        children: [
          {
            path: 'partners',
            name: 'partners',
            meta: {role: ['admin'], permission: 'clients'  , type : 'parent'},
            component: () => import('../components/PromoCodesAndPartners/Partners/List.vue'),
            children: [
              {
                path: 'create/:client_type',
                name: 'partnersCreate',
                meta: {role: ['admin'], permission: 'clients_create'  , type : 'child'},
                component: () => import('../components/PromoCodesAndPartners/Partners/Create.vue')
              }, {
                path: 'update',
                name: 'partnersUpdate',
                meta: {role: ['admin'], permission: 'clients_edit'  , type : 'child'},
                component: () => import('../components/PromoCodesAndPartners/Partners/Update.vue')
              }
            ]
          },
          {
            path: 'promo-codes',
            name: 'promoCodes',
            meta: {role: ['admin'], permission: 'promo_codes'  , type : 'parent'},
            component: () => import('../components/PromoCodesAndPartners/PromoCodes/List.vue'),
            children: [
              {
                path: 'create',
                name: 'promoCodesCreate',
                meta: {role: ['admin'], permission: 'promo_codes_create'  , type : 'child'},
                component: () => import('../components/PromoCodesAndPartners/PromoCodes/Create.vue')
              }, {
                path: 'update',
                name: 'promoCodesUpdate',
                meta: {role: ['admin'], permission: 'promo_codes_edit'  , type : 'child'},
                component: () => import('../components/PromoCodesAndPartners/PromoCodes/Update.vue')
              }
            ]
          },
        ]
      },
      {
        path: 'configure-departments-and-groups',
        name: 'configureDepartmentsAndGroups',
        // redirect: 'configure-departments-and-groups/departments',
        children: [
          {
            path: 'departments',
            name: 'ConfigureDepartments',
            component: () => import('../components/configureDepartmentsAndGroups/departments/list.vue'),
            children: [
              {
                path: 'create',
                name: 'ConfigureDepartmentsCreate',
                component: () => import('../components/configureDepartmentsAndGroups/departments/create.vue')
              }
            ]
          },
          {
            path: 'groups',
            name: 'ConfigureGroups',
            component: () => import('../components/configureDepartmentsAndGroups/groups/list.vue'),
            children: [
              {
                path: 'create',
                name: 'ConfigureGroupsCreate',
                component: () => import('../components/configureDepartmentsAndGroups/groups/create.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'basic-data',
        name: 'BasicData',
        // redirect: '/basic-data/organizational-chart',
        children: [
          {
            path: 'organizational-chart',
            name: 'organizationalChart',
            meta: {role: ['admin'],type: 'none'},
            component: () => import('../components/BasicData/organizationalChart/list.vue'),
            children: [
              {
                path: 'create',
                name: 'organizationalChartCreate',
                component: () => import('../components/BasicData/organizationalChart/create.vue')
              }, {
                path: 'update',
                name: 'organizationalChartUpdate',
                component: () => import('../components/BasicData/organizationalChart/update.vue')
              }
            ]
          },
          {
            path: 'points-and-rates',
            name: 'pointsAndRates',
            meta: {role: ['admin'], permission: 'rates'  , type : 'parent'},
            component: () => import('../components/BasicData/pointsAndRates/list.vue'),
            children: [
              {
                path: 'create',
                name: 'pointsAndRatesCreate',
                meta: { role: ['admin'],permission: 'rates_create'  , type : 'child'},
                component: () => import('../components/BasicData/pointsAndRates/create.vue')
              }, {
                path: 'update',
                name: 'pointsAndRatesUpdate',
                meta: {role: ['admin'], permission: 'rates_edit'  , type : 'child'},
                component: () => import('../components/BasicData/pointsAndRates/update.vue')
              }
            ]
          },
          {
            path: 'types-of-work-teams',
            name: 'typesOfWorkTeams',
            meta: {role: ['admin'], permission: 'work_teams'  , type : 'parent'},
            component: () => import('../components/BasicData/typesOfWorkTeams/list.vue'),
            children: [
              {
                path: 'create',
                name: 'typesOfWorkTeamsCreate',
                meta: {role: ['admin'], permission: 'work_teams_create'  , type : 'child'},
                component: () => import('../components/BasicData/typesOfWorkTeams/create.vue')
              },
              {
                path: 'update',
                name: 'typesOfWorkTeamsUpdate',
                meta: {role: ['admin'], permission: 'work_teams_edit'  , type : 'child'},
                component: () => import('../components/BasicData/typesOfWorkTeams/update.vue')
              },
              {
                path: 'show',
                name: 'typesOfWorkTeamsShow',
                meta: {role: ['admin'], permission: 'work_teams_read'  , type : 'child'},
                component: () => import('../components/BasicData/typesOfWorkTeams/show.vue')
              },
            ]
          },
          {
            path: 'work-teams',
            name: 'workTeams',
            meta: {role: ['admin'], permission: 'teams'  , type : 'parent'},
            component: () => import('../components/BasicData/workTeams/list.vue'),
            children: [
              {
                path: 'create',
                name: 'workTeamsCreate',
                meta: {role: ['admin'], permission: 'teams_create'  , type : 'child'},
                component: () => import('../components/BasicData/workTeams/create.vue')
              }, {
                path: 'update',
                name: 'workTeamsUpdate',
                meta: { role: ['admin'],permission: 'teams_edit'  , type : 'child'},
                component: () => import('../components/BasicData/workTeams/update.vue')
              },
            ]
          },
          {
            path: 'departments',
            name: 'departments',
            meta: {role: ['admin'], permission: 'departments'  , type : 'parent'},
            component: () => import('../components/BasicData/departments/list.vue'),
            children: [
              {
                path: 'create',
                name: 'departmentsCreate',
                meta: {role: ['admin'], permission: 'departments_create'  , type : 'parent'},
                component: () => import('../components/BasicData/departments/create.vue')
              }, {
                path: 'update',
                name: 'departmentsUpdate',
                meta: {role: ['admin'], permission: 'departments_edit'  , type : 'parent'},
                component: () => import('../components/BasicData/departments/update.vue')
              },
            ]
          },
          {
            path: 'groups',
            name: 'groups',
            meta: { role: ['admin'],permission: 'groups'  , type : 'parent'},
            component: () => import('../components/BasicData/groups/list.vue'),
            children: [
              {
                path: 'create',
                name: 'groupsCreate',
                meta: { role: ['admin'],permission: 'groups_create'  , type : 'child'},
                component: () => import('../components/BasicData/groups/create.vue')
              }, {
                path: 'update',
                name: 'groupsUpdate',
                meta: { role: ['admin'],permission: 'groups_edit'  , type : 'child'},
                component: () => import('../components/BasicData/groups/update.vue')
              },
            ]
          },
          {
            path: 'divisions',
            name: 'divisions',
            meta: {role: ['admin'], permission: 'divisions'  , type : 'parent'},
            component: () => import('../components/BasicData/divisions/list.vue'),
            children: [
              {
                path: 'create',
                name: 'divisionsCreate',
                meta: {role: ['admin'], permission: 'divisions_create'  , type : 'child'},
                component: () => import('../components/BasicData/divisions/create.vue')
              }, {
                path: 'update',
                name: 'divisionsUpdate',
                meta: {role: ['admin'], permission: 'divisions_edit'  , type : 'child'},
                component: () => import('../components/BasicData/divisions/update.vue')
              },
            ]
          },
          {
            path: 'sections',
            name: 'sections',
            meta: {role: ['admin'], permission: 'sections'  , type : 'child'},
            component: () => import('../components/BasicData/sections/list.vue'),
            children: [
              {
                path: 'create',
                name: 'sectionsCreate',
                meta: {role: ['admin'], permission: 'sections_create'  , type : 'child'},
                component: () => import('../components/BasicData/sections/create.vue')
              }, {
                path: 'update',
                name: 'sectionsUpdate',
                meta: {role: ['admin'], permission: 'sections_edit'  , type : 'child'},
                component: () => import('../components/BasicData/sections/update.vue')
              },
            ]
          },
          {
            path: 'subjects',
            name: 'subjects',
            meta: { role: ['admin'],permission: 'subjects'  , type : 'parent'},
            component: () => import('../components/BasicData/subjects/list.vue'),
            children: [
              {
                path: 'create',
                name: 'subjectsCreate',
                meta: {role: ['admin'], permission: 'subjects_create'  , type : 'child'},
                component: () => import('../components/BasicData/subjects/create.vue')
              }, {
                path: 'update',
                name: 'subjectsUpdate',
                meta: {role: ['admin'], permission: 'subjects_edit'  , type : 'child'},
                component: () => import('../components/BasicData/subjects/update.vue')
              },
            ]
          },
          {
            path: 'subjects-by-division',
            name: 'subjectsByDivision',
            component: () => import('../components/BasicData/subjectsByDivision/list.vue'),
            children: [
              {
                path: 'create',
                name: 'subjectsByDivisionCreate',
                component: () => import('../components/BasicData/subjectsByDivision/create.vue')
              },
            ]
          },
          {
            path: 'lessons',
            name: 'lessons',
            meta: {role: ['admin'], permission: 'lessons'  , type : 'parent'},
            component: () => import('../components/BasicData/lessons/list.vue'),
            children: [
              {
                path: 'create',
                name: 'lessonsCreate',
                meta: {role: ['admin'], permission: 'lessons_create'  , type : 'child'},
                component: () => import('../components/BasicData/lessons/create.vue')
              }, {
                path: 'update',
                name: 'lessonsUpdate',
                meta: {role: ['admin'], permission: 'lessons_edit'  , type : 'child'},
                component: () => import('../components/BasicData/lessons/update.vue')
              },
            ]
          },
          {
            path: 'categories',
            name: 'categories',
            meta: {role: ['admin'], permission: 'categories'  , type : 'child'},
            component: () => import('../components/BasicData/categories/list.vue'),
            children: [
              {
                path: 'create',
                name: 'categoriesCreate',
                meta: { role: ['admin'],permission: 'categories_create'  , type : 'child'},
                component: () => import('../components/BasicData/categories/create.vue')
              }, {
                path: 'update',
                name: 'categoriesUpdate',
                meta: {role: ['admin'], permission: 'categories_edit'  , type : 'child'},
                component: () => import('../components/BasicData/categories/update.vue')
              },
            ]
          },
          {
            path: 'rate-categories',
            name: 'rateCategories',
            meta: {role: ['admin'], permission: 'category_values'  , type : 'parent'},
            component: () => import('../components/BasicData/rateCategories/list.vue'),
            children: [
              {
                path: 'create',
                name: 'rateCategoriesCreate',
                meta: {role: ['admin'], permission: 'category_values_create'  , type : 'child'},
                component: () => import('../components/BasicData/rateCategories/create.vue')
              }, {
                path: 'update',
                name: 'rateCategoriesUpdate',
                meta: {role: ['admin'], permission: 'category_values_edit'  , type : 'child'},
                component: () => import('../components/BasicData/rateCategories/update.vue')
              },
            ]
          },
          {
            path: 'tags',
            name: 'tags',
            meta: {role: ['admin'], permission: 'tags'  , type : 'parent'},
            component: () => import('../components/BasicData/tags/list.vue'),
            children: [
              {
                path: 'create',
                name: 'tagsCreate',
                meta: {role: ['admin'], permission: 'tags_create'  , type : 'child'},
                component: () => import('../components/BasicData/tags/create.vue')
              }, {
                path: 'update',
                name: 'tagsUpdate',
                meta: {role: ['admin'], permission: 'tags_create'  , type : 'child'},
                component: () => import('../components/BasicData/tags/update.vue')
              },
            ]
          },
          {
            path: 'certificates',
            name: 'certificates',
            meta: {role: ['admin'], permission: 'certificates'  , type : 'parent'},
            component: () => import('../components/BasicData/certificates/list.vue'),
            children: [
              {
                path: 'create',
                name: 'certificatesCreate',
                meta: {role: ['admin'], permission: 'certificates_create'  , type : 'child'},
                component: () => import('../components/BasicData/certificates/create.vue')
              }, {
                path: 'update',
                name: 'certificatesUpdate',
                meta: {role: ['admin'], permission: 'certificates_edit'  , type : 'child'},
                component: () => import('../components/BasicData/certificates/update.vue')
              },
            ]
          },
          {
            path: 'messageTemplates',
            name: 'messageTemplates',
            meta: {role: ['admin'], permission: 'exam_messages'  , type : 'parent'},
            component: () => import('../components/BasicData/messageTemplates/list.vue'),
            children: [
              {
                path: 'create',
                name: 'messageTemplatesCreate',
                meta: {role: ['admin'], permission: 'exam_messages_create'  , type : 'child'},
                component: () => import('../components/BasicData/messageTemplates/create.vue')
              }, {
                path: 'update',
                name: 'messageTemplatesUpdate',
                meta: { role: ['admin'],permission: 'exam_messages_edit'  , type : 'child'},
                component: () => import('../components/BasicData/messageTemplates/update.vue')
              },
            ]
          }
        ]
      },
      {
        path: 'platform-settings',
        name: 'PlatformSettings',
        meta: {role: ['admin'], permission: 'settings'  , type : 'parent'},
        // redirect: '/platform-settings/settings',
        children: [
          {
            path: 'settings',
            name: 'settings',
            component: () => import('../components/PlatformSettings/settings/list.vue'),
          },
          {
            path: 'product-info',
            name: 'productInfo',
            component: () => import('../components/PlatformSettings/productInfo/list.vue'),
          },
          {
            path: 'model-units',
            name: 'modelUnits',
            component: () => import('../components/PlatformSettings/modelUnits/list.vue'),
          },
          {
            path: 'basic-data',
            name: 'basicData',
            component: () => import('../components/PlatformSettings/basicData/list.vue'),
          },
          {
            path: 'payment-system',
            name: 'paymentSystem',
            component: () => import('../components/PlatformSettings/paymentSystem/list.vue')
          },          {
            path: 'mail-and-notifications',
            name: 'mailAndNotifications',
            component: () => import('../components/PlatformSettings/MailAndNotifications/list.vue')
          },
          {
            path: 'manage-prices-packages',
            name: 'managePricesPackages',
            component: () => import('../components/PlatformSettings/managePricesPackages/list.vue'),
            children: [
              {
                path: 'create',
                name: 'managePricesPackagesCreate',
                component: () => import('../components/PlatformSettings/managePricesPackages/create.vue')
              }, {
                path: 'update',
                name: 'managePricesPackagesUpdate',
                component: () => import('../components/PlatformSettings/managePricesPackages/update.vue')
              }
            ]
          },
          {
            path: 'payment-systems',
            name: 'paymentSystems',
            component: () => import('../components/PlatformSettings/paymentSystems/list.vue'),
            children: [
              {
                path: 'create',
                name: 'paymentSystemsCreate',
                component: () => import('../components/PlatformSettings/paymentSystems/create.vue')
              }, {
                path: 'update',
                name: 'paymentSystemsUpdate',
                component: () => import('../components/PlatformSettings/paymentSystems/update.vue')
              }
            ]
          },
          {
            path: 'other-settings',
            name: 'otherSettings',
            component: () => import('../components/PlatformSettings/otherSettings/list.vue'),
          },
          {
            path: 'interface-management',
            name: 'InterfaceManagement',
            component: () => import('../components/PlatformSettings/InterfaceManagement/list.vue'),
          },
          {
            path: 'license-settings',
            name: 'licenseSettings',
            component: () => import('../components/PlatformSettings/licenseSettings/list.vue'),
          },
          {
            path: 'share-content',
            name: 'shareContent',
            component: () => import('../components/PlatformSettings/shareContent/list.vue'),
          }
        ]
      },
      {
        path: 'create-question',
        name: 'createQuestion',
        component: () => import('../components/MCQsettingsModels/create-question.vue')
      },
      {
        path: 'MCQ-settings-Models',
        name: 'MCQsettingsModels',
        // redirect: '/MCQ-settings-models/original-settings',
        children: [
          {
            path: 'original-settings',
            name: 'OriginalSettings',
            meta: { permission: 'templates'  , type : 'parents'},
            component: () => import('../components/MCQsettingsModels/originalSettings/list.vue'),
          },
          {
            path: 'form-management',
            name: 'FormManagement',
            meta: { permission: 'templates'  , type : 'parent'},
            component: () => import('../components/MCQsettingsModels/formManagement/list.vue'),
            children: [
              {
                path: 'create',
                name: 'FormManagementCreate',
                meta: { permission: 'templates_create'  , type : 'child'},
                component: () => import('../components/MCQsettingsModels/formManagement/create.vue')
              }, {
                path: 'update',
                name: 'FormManagementUpdate',
                meta: { permission: 'templates_edit'  , type : 'child'},
                component: () => import('../components/MCQsettingsModels/formManagement/update.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'questions',
        name: 'QuestionList',
        meta: {role: ['admin'], permission: 'questions'  , type : 'parent'},
        component: () => import('../components/MCQsettingsModels/create-question-list.vue'),
        children: [
          {
            path: 'create',
            name: 'createQuestion',
            meta: {role: ['admin'], permission: 'questions_create'  , type : 'child'},
            component: () => import('../components/MCQsettingsModels/create-question.vue')
          },
          {
            path: 'edit/:id',
            name: 'editQuestion',
            meta: { role: ['admin'],permission: 'questions_edit'  , type : 'child'},
            component: () => import('../components/MCQsettingsModels/edit-question.vue')
          },
          {
            path: 'history/:id',
            name: 'historyQuestion',
            meta: { role: ['admin'],permission: 'questions_read'  , type : 'child'},
            component: () => import('../components/MCQsettingsModels/history-question.vue')
          },
          {
            path: 'show/:id',
            name: 'showQuestion',
            meta: { permission: 'questions_read'  , type : 'child'},
            component: () => import('../components/MCQsettingsModels/show-question.vue')
          },
          {
            path: 'import',
            name: 'importQuestions',
            component: () => import('../components/MCQsettingsModels/import-questions.vue')
          },
        ]
      },
      {
        path: 'messageTemplates',
        name: 'messageTemplateList',
        component: () => import('../components/MessageTemplates/message-template-list.vue'),
        children: [
          {
            path: 'create',
            name: 'createMessageTemplate',
            component: () => import('../components/MessageTemplates/create-message-template.vue')
          },
          {
            path: 'edit/:id',
            name: 'editMessageTemplate',
            component: () => import('../components/MessageTemplates/edit-message-template.vue')
          },
        ]
      },
      {
        path: 'question-templates',
        name: 'QuestionsTemplates',
        component: () => import('../components/QuestionsTemplates/list.vue'),
        children: [
          {
            path: 'create',
            name: 'questionsTemplatesCreate',
            component: () => import('../components/QuestionsTemplates/create.vue')
          }, {
            path: 'update',
            name: 'questionsTemplatesUpdate',
            component: () => import('../components/QuestionsTemplates/update.vue')
          },
        ]
      },
      // {
      //   path: '/manage-prices-packages',
      //   name: 'ManagePricesPackages',
      //   component: () => import ('../components/ManagePricesPackages/List.vue')
      // },
      {
        path: 'managing-work-team',
        name: 'ManagingWorkTeam',
        meta: { permission: 'teams'  , type : 'parent'},
        component: () => import('../components/ManagingWorkTeam/List.vue'),
        children: [
          {
            path: 'create',
            name: 'CountriesCreate',
            meta: { permission: 'teams_create'  , type : 'child'},
            component: () => import('../components/ManagingWorkTeam/Create.vue')
          },
        ]
      },
      {
        path: 'affiliates',
        name: 'Affiliates',
        component: () => import('../components/Affiliates/List.vue')
      },
      {
        path: 'clients',
        name: 'Clients',
        component: () => import('../components/Clients/List.vue'),
      },
      {
        path: 'work-team',
        name: 'WorkTeam',
        component: () => import('../components/WorkTeam/List.vue'),
      },
      /* start user mangment */
      {
        path: 'user-mangment',
        name: 'UserMangment',
        meta: {  type : 'none'},
        // redirect: '/user-mangment/permission-management',
        children: [
          {
            path: 'permission-management',
            name: 'PermissionManagement',
            meta: { type : 'none'},
            component: () => import('../components/UserMangment/PermissionManagement/List.vue'),
            children: [
              {
                path: 'create',
                name: 'PermissionManagementCreate',
                meta: { permission: 'role_create'  , type : 'child'},
                component: () => import('../components/UserMangment/PermissionManagement/Create.vue')
              },
              {
                path: 'update',
                name: 'PermissionManagementUpdate',
                meta: { permission: 'role_edit'  , type : 'child'},
                component: () => import('../components/UserMangment/PermissionManagement/Update.vue')
              }
            ]
          },
          {
            path: 'users-permissionmanagement',
            name: 'UsersPermissionManagement',
            meta: { type : 'none'},
            component: () => import('../components/UserMangment/UsersPermissionManagement/List.vue'),
            children: [
              {
                path: 'create',
                name: 'UsersPermissionManagementCreate',
                meta: { permission: 'users_create'  , type : 'child'},
                component: () => import('../components/UserMangment/UsersPermissionManagement/Create.vue')
              },
              {
                path: 'update',
                name: 'UsersPermissionManagementUpdate',
                meta: { permission: 'users_edit'  , type : 'child'},
                component: () => import('../components/UserMangment/UsersPermissionManagement/Update.vue')
              }
            ]
          },
        ]
      },
      {
        path: 'MCQ-exams',
        name: 'MCQExams',
        meta: {role: ['admin'], permission: 'exams'  , type : 'parent'},
        component: () => import('../components/MCQExams/List.vue'),
      },
      {
        path: 'exam-models',
        name: 'ExamModels',
        meta: {role: ['admin'], permission: 'ex_templates'  , type : 'parent'},
        component: () => import('../components/ExamModels/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ExamModelsCreate',
            meta: {role: ['admin'], permission: 'ex_templates_create'  , type : 'child'},
            component: () => import('../components/ExamModels/Create.vue')
          }, {
            path: 'update',
            name: 'ExamModelsUpdate',
            meta: {role: ['admin'], permission: 'ex_templates_edit'  , type : 'child'},
            component: () => import('../components/ExamModels/Update.vue')
          },{
            path: 'show',
            name: 'ExamModelsShow',
            meta: {role: ['admin'], permission: 'ex_templates_show'  , type : 'child'},
            component: () => import('../components/ExamModels/Show.vue')
          },
        ]
      },
      /* HERE HERE */
      {
        path: 'exercise-template',
        name: 'ExerciseTemplate',
        meta: { permission: 'ex_templates'  , type : 'parent'},
        component: () => import('../components/ExerciseTemplate/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ExerciseTemplateCreate',
            meta: { permission: 'ex_templates_create'  , type : 'child'},
            component: () => import('../components/ExerciseTemplate/Create.vue')
          }, {
            path: 'update',
            name: 'ExerciseTemplateUpdate',
            meta: { permission: 'ex_templates_edit'  , type : 'child'},
            component: () => import('../components/ExerciseTemplate/Update.vue')
          },
        ]
      },
      {
        path: 'exercises',
        name: 'Exercises',
        meta: {role: ['admin'], permission: 'ex_templates'  , type : 'parent'},
        component: () => import('../components/Exercises/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ExercisesCreate',
            meta: {role: ['admin'], permission: 'ex_templates_create'  , type : 'child'},
            component: () => import('../components/Exercises/Create.vue')
          }, {
            path: 'update',
            name: 'ExercisesUpdate',
            meta: {role: ['admin'], permission: 'ex_templates_edit'  , type : 'child'},
            component: () => import('../components/Exercises/Update.vue')
          },
        ]
      },
      {
        path: 'MCQ-exams/create',
        name: 'CreateMCQExams',
        meta: {role: ['admin'], permission: 'exams_create'  , type : 'child'},
        component: () => import('../components/MCQExams/Create.vue'),
      },
      {
        path: 'MCQ-exams/:id/edit',
        name: 'EditMCQExams',
        meta: { role: ['admin'],permission: 'exams_edit'  , type : 'child'},
        component: () => import('../components/MCQExams/Update.vue'),
      },
      {
        path: 'exams',
        name: 'Exams',
        meta: {role: ['admin'], permission: 'full_exams'  , type : 'parent'},
        component: () => import('../components/Exams/List.vue'),
        children: [
          {
            path: 'create',
            name: 'CreateExams',
            meta: {role: ['admin'], permission: 'full_exams_create'  , type : 'child'},
            component: () => import('../components/Exams/Create.vue'),
          },
          {
            path: 'update',
            name: 'UpdateExams',
            meta: {role: ['admin'], permission: 'full_exams_edit'  , type : 'child'},
            component: () => import('../components/Exams/Update.vue'),
          },
          
        ]
      },
      
      {
        path: 'TopicsDuplicate',
        name: 'TopicsDuplicate',
        component: () => import('../components/ModelTopics/Duplicate.vue'),
      },
      {
        path: 'ExercisesDuplicate',
        name: 'ExercisesDuplicate',
        component: () => import('../components/Exercises/Duplicate.vue'),
      },
      {
        path: 'exercise-models',
        name: 'ModelExercise',
        meta: {role: ['admin'], permission: 'topics'  , type : 'parent'},
        component: () => import('../components/ModelExercise/List.vue'),
        children: [
          {
            path: 'create',
            name: 'ExerciseCreate',
            meta: {role: ['admin'], permission: 'topics_create'  , type : 'child'},
            component: () => import('../components/ModelExercise/Create.vue')
          }, {
            path: 'update',
            name: 'ExerciseUpdate',
            meta: {role: ['admin'], permission: 'topics_edit'  , type : 'child'},
            component: () => import('../components/ModelExercise/Update.vue')
          },
        ]
      },
      {
        path: 'ExerciseCreate',
        name: 'ExerciseCreate',
        component: () => import('../components/ModelExercise/Create.vue'),

      },
      {
        path: 'ExerciseUpdate',
        name: 'ExerciseUpdate',
        component: () => import('../components/ModelExercise/Update.vue'),

      },
      {
        path: 'baskets',
        name: 'Baskets',
        // redirect: '/baskets/questions-basket',
        children: [
          {
            path: 'questions-basket',
            name: 'QuestionsBasket',
            meta: {role: ['admin'], permission: 'baskets'  , type : 'parent'},
            component: () => import('../components/Baskets/QuestionsBasket/List.vue'),
            children: [
              {
                path: 'create',
                name: 'QuestionsBasketCreate',
                meta: {role: ['admin'], permission: 'baskets_create'  , type : 'child'},
                component: () => import('../components/Baskets/QuestionsBasket/Create.vue')
              }, {
                path: 'update',
                name: 'QuestionsBasketUpdate',
                meta: { role: ['admin'],permission: 'baskets_edit'  , type : 'child'},
                component: () => import('../components/Baskets/QuestionsBasket/Update.vue')
              }
            ]
          },
          {
            path: 'filling-baskets',
            name: 'FillingBaskets',
            meta: { permission: 'baskets'  , type : 'parent'},
            component: () => import('../components/Baskets/FillingBaskets/List.vue'),
          },



        ]
      },
      {
        path: 'TypesOfFiles',
        name: 'TypesOfFiles',
        meta: {role: ['admin'], permission: 'library_types'  , type : 'parent'},
        component: () => import('../components/Libraries/TypesOfFiles/list.vue'),
        children: [
          {
            path: 'create',
            name: 'TypesOfFilesCreate',
            meta: {role: ['admin'], permission: 'library_types_create'  , type : 'child'},
            component: () => import('../components/Libraries/TypesOfFiles/create.vue')
          }, {
            path: 'update',
            name: 'TypesOfFilesUpdate',
            meta: {role: ['admin'], permission: 'library_types_edit'  , type : 'child'},
            component: () => import('../components/Libraries/TypesOfFiles/update.vue')
          },
        ]
      },
      {
        path: 'ExtensionsOfFiles',
        name: 'ExtensionsOfFiles',
        meta: {role: ['admin'], permission: 'library_extensions', type : 'parent'},
        component: () => import('../components/Libraries/ExtensionsOfFiles/list.vue'),
        children: [
          {
            path: 'create',
            name: 'ExtensionsOfFilesCreate',
            meta: {role: ['admin'], permission: 'library_extensions_create', type : 'child'},
            component: () => import('../components/Libraries/ExtensionsOfFiles/create.vue')
          }, {
            path: 'update',
            name: 'ExtensionsOfFilesUpdate',
            meta: {role: ['admin'], permission: 'library_extensions_edit', type : 'child'},
            component: () => import('../components/Libraries/ExtensionsOfFiles/update.vue')
          },
        ]
      },
      {
        path: 'files',
        name: 'Files',
        meta: {role: ['admin'], permission: 'libraries'  , type : 'parent'},
        component: () => import('../components/Libraries/Files/list.vue'),
        children: [
          {
            path: 'create',
            name: 'FilesCreate',
            meta: {role: ['admin'], permission: 'libraries_create'  , type : 'child'},
            component: () => import('../components/Libraries/Files/create.vue')
          }, {
            path: 'update',
            name: 'FilesUpdate',
            meta: {role: ['admin'], permission: 'libraries_edit'  , type : 'child'},
            component: () => import('../components/Libraries/Files/update.vue')
          },
        ]
      },
      {
        path: 'tracks',
        name: 'Tracks',
        meta: {role: ['admin'], permission: 'tracks'  , type : 'parent'},
        component: () => import('../components/Tracks/List.vue'),
        children: [
          {
            path: 'create',
            name: 'TracksCreate',
            meta: {role: ['admin'], permission: 'tracks_create'  , type : 'child'},
            component: () => import('../components/Tracks/Create.vue')
          }, {
            path: 'update',
            name: 'TracksUpdate',
            meta: {role: ['admin'], permission: 'tracks_edit'  , type : 'child'},
            component: () => import('../components/Tracks/Update.vue')
          },
        ]
      },
      {
        path: 'ads',
        name: 'Ads',
        meta: {role: ['admin'], permission: 'ads'  , type : 'parent'},
        component: () => import('../components/Ads/List.vue'),
        children: [
          {
            path: 'create',
            name: 'AdsCreate',
            meta: {role: ['admin'], permission: 'ads_create'  , type : 'child'},
            component: () => import('../components/Ads/Create.vue')
          }, {
            path: 'update',
            name: 'AdsUpdate',
            meta: {role: ['admin'], permission: 'ads_edit'  , type : 'child'},
            component: () => import('../components/Ads/Update.vue')
          },
        ]
      },
      {
        path: 'system-reports',
        name: 'SystemReports',
        meta: {role: ['admin'],type: 'none'},
        component: () => import('../components/SystemReports/List.vue'),
        children: [
          {
            path: 'reports-types',
            name: 'ReportsTypes',
            meta: {role: ['admin'], permission: 'reports' , type: 'parent'},
            component: () => import('../components/SystemReports/ReportsTypes/List.vue'),
            children: [
              {
                path: 'create',
                name: 'ReportsTypesCreate',
                meta: {role: ['admin'], permission: 'reports_create' , type: 'child'},
                component: () => import('../components/SystemReports/ReportsTypes/Create.vue')
              }, {
                path: 'update',
                name: 'ReportsTypesUpdate',
                meta: { role: ['admin'],permission: 'reports_edit' , type: 'child'},
                component: () => import('../components/SystemReports/ReportsTypes/Update.vue')
              }
            ]
          },
          {
            path: 'user-reports',
            name: 'UserReports',
            meta: { permission: 'report_users' , type: 'parent'},
            component: () => import('../components/SystemReports/UserReports/List.vue'),

          },
        ]
      },
      {
        path: 'feedbacks',
        name: 'Feedbacks',
        meta: {role: ['admin'],type: 'none'},
        component: () => import('../components/Feedbacks/List.vue'),
        children: [
          {
            path: 'feedback-types',
            name: 'FeedbackTypes',
            meta: {role: ['admin'], permission: 'feedback' , type: 'parent'},
            component: () => import('../components/Feedbacks/FeedbackTypes/List.vue'),
            children: [
              {
                path: 'create',
                name: 'FeedbackTypesCreate',
                meta: {role: ['admin'], permission: 'feedback_create' , type: 'child'},
                component: () => import('../components/Feedbacks/FeedbackTypes/Create.vue')
              }, {
                path: 'update',
                name: 'FeedbackTypesUpdate',
                meta: {role: ['admin'], permission: 'feedback_edit' , type: 'child'},
                component: () => import('../components/Feedbacks/FeedbackTypes/Update.vue')
              }
            ]
          }, {
            path: 'feedback-user-exam',
            name: 'FeedbackUserExam',
            meta: { permission: 'feedback_user_exams' , type: 'parent'},
            component: () => import('../components/Feedbacks/FeedbackUserExam/List.vue'),

          }, {
            path: 'feedback-comments',
            name: 'FeedbackComments',
            meta: { permission: 'feedback_exam_comments' , type: 'parent'},
            component: () => import('../components/Feedbacks/FeedbackComments/List.vue'),

          },

        ]
      },
      {
        path: 'lives',
        name: 'Lives',
        meta: {role: ['admin'], permission: 'lives' , type: 'parent'},
        component: () => import('../components/Lives/List.vue'),
        children: [
          {
            path: 'create',
            name: 'LivesCreate',
            meta: {role: ['admin'], permission: 'lives_create' , type: 'child'},
            component: () => import('../components/Lives/Create.vue')
          }, {
            path: 'update',
            name: 'LivesUpdate',
            meta: {role: ['admin'], permission: 'lives_edit' , type: 'child'},
            component: () => import('../components/Lives/Update.vue')
          }
        ]
      },


    ]
  },
  {
    path: '/:schoolName/login',
    name: 'Login',
    component: LoginView
  },

  {
    path: '/:schoolName/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/',
    name: 'first',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

  {
    path: "/:schoolName/:catchAll(.*)",
    name: "NotFound",
    meta:{title:'errorPageMsg'},
    component: () => import ('../components/PageNotFound'),
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.addRoute({
  path: '/:schoolName/student',
  name: 'student',
  component: StudentView,
  children: [
    {
      path: '',
      name: 'StudentVue',
      component: () => import('../components/Students/Components/student.vue')
    },
    {
      path: 'mcq',
      name: 'MCQ',
      component: () => import('../components/Students/Components/MCQ/mcq.vue'),
      children: [
        {
          path: 'steps-assessments',
          name: 'stepsAssessments',
          component: () => import('../components/Students/Components/MCQ/stepsAssessments/instructions.vue'),
          children: [
            {
              path: 'start',
              name: 'startAssessments',
              component: () => import('../components/Students/Components/MCQ/stepsAssessments/start.vue'),
            },
            {
              path: 'questions',
              name: 'questionsAssessments',
              component: () => import('../components/Students/Components/MCQ/stepsAssessments/questions.vue'),
            },
            {
              path: 'unanswered-marked',
              name: 'UnansweredMarkedAssessments',
              component: () => import('../components/Students/Components/MCQ/stepsAssessments/unanswered-marked.vue'),
            },
            {
              path: 'feedback',
              name: 'feedbackAssessments',
              component: () => import('../components/Students/Components/MCQ/stepsAssessments/feedback.vue'),
            },
            {
              path: 'statistics',
              name: 'statisticsAssessments',
              component: () => import('../components/Students/Components/MCQ/stepsAssessments/statistics.vue'),
            }
          ]
        },
        {
          path: 'steps-exams',
          name: 'stepsExams',
          component: () => import('../components/Students/Components/MCQ/stepsExams/instructions.vue'),
          children: [
            {
              path: 'start',
              name: 'startExams',
              component: () => import('../components/Students/Components/MCQ/stepsExams/start.vue'),
            },
            {
              path: 'questions',
              name: 'questionsExams',
              component: () => import('../components/Students/Components/MCQ/stepsExams/questions.vue'),
            }
          ]
        },
        {
          path: 'steps-scheduled-exams',
          name: 'stepsScheduledExams',
          component: () => import('../components/Students/Components/MCQ/stepsScheduledExams/instructions.vue'),
          children: [
            {
              path: 'start',
              name: 'startScheduledExams',
              component: () => import('../components/Students/Components/MCQ/stepsScheduledExams/start.vue'),
            },
            {
              path: 'questions',
              name: 'questionsScheduledExams',
              component: () => import('../components/Students/Components/MCQ/stepsScheduledExams/questions.vue'),
            }
          ]
        }
      ]
    },
    {
      path: 'exams-html',
      name: 'examsHTML',
      component: () => import('../components/Students/Components/examsHTML/exams.vue'),
      children: [
        {
          path: 'steps-assessments',
          name: 'stepsAssessments',
          component: () => import('../components/Students/Components/examsHTML/stepsAssessments/instructions.vue'),
          children: [
            {
              path: 'start',
              name: 'startAssessments',
              component: () => import('../components/Students/Components/examsHTML/stepsAssessments/start.vue'),
            },
            {
              path: 'questions',
              name: 'questionsAssessments',
              component: () => import('../components/Students/Components/examsHTML/stepsAssessments/questions.vue'),
            },
            {
              path: 'unanswered-marked',
              name: 'UnansweredMarkedAssessments',
              component: () => import('../components/Students/Components/examsHTML/stepsAssessments/unanswered-marked.vue'),
            },
            {
              path: 'feedback',
              name: 'feedbackAssessments',
              component: () => import('../components/Students/Components/examsHTML/stepsAssessments/feedback.vue'),
            },
            {
              path: 'statistics',
              name: 'statisticsAssessments',
              component: () => import('../components/Students/Components/examsHTML/stepsAssessments/statistics.vue'),
            }
          ]
        },
        {
          path: 'steps-exams',
          name: 'stepsExams',
          component: () => import('../components/Students/Components/MCQ/stepsExams/instructions.vue'),
          children: [
            {
              path: 'start',
              name: 'startExams',
              component: () => import('../components/Students/Components/MCQ/stepsExams/start.vue'),
            },
            {
              path: 'questions',
              name: 'questionsExams',
              component: () => import('../components/Students/Components/MCQ/stepsExams/questions.vue'),
            }
          ]
        },
        {
          path: 'steps-scheduled-exams',
          name: 'stepsScheduledExams',
          component: () => import('../components/Students/Components/MCQ/stepsScheduledExams/instructions.vue'),
          children: [
            {
              path: 'start',
              name: 'startScheduledExams',
              component: () => import('../components/Students/Components/MCQ/stepsScheduledExams/start.vue'),
            },
            {
              path: 'questions',
              name: 'questionsScheduledExams',
              component: () => import('../components/Students/Components/MCQ/stepsScheduledExams/questions.vue'),
            }
          ]
        }
      ]
    },
    {
      path: 'sales',
      name: 'SalesStudent',
      component: () => import('../components/Students/Components/Sales/List.vue'),
      children: [
        {
          path: 'create',
          name: 'SalesStudentCreate',
          meta: { role: ['admin'] },
          component: () => import('../components/Students/Components/Sales/Create.vue')
        }, {
          path: 'update',
          name: 'SalesStudentUpdate',
          meta: { role: ['admin']},
          component: () => import('../components/Students/Components/Sales/Update.vue')
        }, {
          path: 'show',
          name: 'SalesStudentShow',
          meta: { role: ['admin'] },
          component: () => import('../components/Students/Components/Sales/Show')
        }
      ]
    },
    {
      path: 'student-mcq-exams',
      name: 'StudentMCQExams',
      component: () => import('../components/Students/Components/MCQExams/List.vue'),
      children: [
        {
          path: 'relax-steps-exams',
          name: 'RelaxExamsStepsExams',
          component: () => import('../components/Students/Components/MCQExams/Exams/RelaxExams/instructions.vue'),
          children: [
            {
              path: 'relax-start',
              name: 'RelaxExamsStart',
              component: () => import('../components/Students/Components/MCQExams/Exams/RelaxExams/start.vue'),
            },
            {
              path: 'relax-questions',
              name: 'RelaxExamsQuestions',
              component: () => import('../components/Students/Components/MCQExams/Exams/RelaxExams/questions.vue'),
            },
            {
              path: 'relax-unanswered-marked',
              name: 'RelaxUnansweredMarkedExams',
              component: () => import('../components/Students/Components/MCQExams/Exams/RelaxExams/unanswered-marked.vue'),
            },
            {
              path: 'relax-feedback',
              name: 'RelaxfeedbackExams',
              component: () => import('../components/Students/Components/MCQExams/Exams/RelaxExams/feedback.vue'),
            },
            {
              path: 'relax-statistics',
              name: 'RelaxStatisticsExams',
              component: () => import('../components/Students/Components/MCQExams/Exams/RelaxExams/statistics.vue'),
            }
          ]
        },
        {
          path: 'chrono-steps-exams',
          name: 'ChronoExamsStepsExams',
          component: () => import('../components/Students/Components/MCQExams/Exams/ChronoExams/instructions.vue'),
          children: [
            {
              path: 'chrono-start',
              name: 'ChronoExamsStart',
              component: () => import('../components/Students/Components/MCQExams/Exams/ChronoExams/start.vue'),
            },
            {
              path: 'chrono-questions',
              name: 'ChronoExamsQuestions',
              component: () => import('../components/Students/Components/MCQExams/Exams/ChronoExams/questions.vue'),
            },
            {
              path: 'chrono-unanswered-marked',
              name: 'ChronoUnansweredMarkedExams',
              component: () => import('../components/Students/Components/MCQExams/Exams/ChronoExams/unanswered-marked.vue'),
            },
            {
              path: 'chrono-feedback',
              name: 'ChronofeedbackExams',
              component: () => import('../components/Students/Components/MCQExams/Exams/ChronoExams/feedback.vue'),
            },
            {
              path: 'chrono-statistics',
              name: 'ChronoStatisticsExams',
              component: () => import('../components/Students/Components/MCQExams/Exams/ChronoExams/statistics.vue'),
            }
          ]
        },
        {
          path: 'steps-ScheduledExams',
          name: 'ScheduledExams',
          component: () => import('../components/Students/Components/MCQExams/ScheduledExams/instructions.vue'),
          children: [
            {
              path: 'scheduled-start',
              name: 'ScheduledStart',
              component: () => import('../components/Students/Components/MCQExams/ScheduledExams/start.vue'),
            },
            {
              path: 'scheduled-questions',
              name: 'ScheduledQuestions',
              component: () => import('../components/Students/Components/MCQExams/ScheduledExams/questions.vue'),
            },
            {
              path: 'scheduled-unanswered-marked',
              name: 'ScheduledUnansweredMarkedExams',
              component: () => import('../components/Students/Components/MCQExams/ScheduledExams/unanswered-marked.vue'),
            },
            {
              path: 'scheduled-feedback',
              name: 'ScheduledfeedbackExams',
              component: () => import('../components/Students/Components/MCQExams/ScheduledExams/feedback.vue'),
            },
            {
              path: 'scheduled-statistics',
              name: 'ScheduledStatisticsExams',
              component: () => import('../components/Students/Components/MCQExams/ScheduledExams/statistics.vue'),
            }
          ]
        },
        {
          path: 'relax-steps-assessment',
          name: 'RelaxStepsAssessment',
          component: () => import('../components/Students/Components/MCQExams/Assessment/RelaxAssessment/instructions.vue'),
          children: [
            {
              path: 'relax-start',
              name: 'RelaxStartAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/RelaxAssessment/start.vue'),
            },
            {
              path: 'relax-questions-assessment',
              name: 'RelaxQuestionsAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/RelaxAssessment/questions.vue'),
            },
            {
              path: 'relax-unanswered-marked-assessment',
              name: 'RelaxUnansweredMarkedAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/RelaxAssessment/unanswered-marked.vue'),
            },
            {
              path: 'relax-feedback-assessment',
              name: 'RelaxfeedbackAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/RelaxAssessment/feedback.vue'),
            },
            {
              path: 'relax-statistics-assessment',
              name: 'RelaxStatisticsAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/RelaxAssessment/statistics.vue'),
            }
          ]
        },
        {
          path: 'chrono-steps-assessment',
          name: 'ChronoStepsAssessment',
          component: () => import('../components/Students/Components/MCQExams/Assessment/ChronoAssessment/instructions.vue'),
          children: [
            {
              path: 'chrono-start-assessment',
              name: 'ChronoStartAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/ChronoAssessment/start.vue'),
            },
            {
              path: 'chrono-questions-assessment',
              name: 'ChronoQuestionsAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/ChronoAssessment/questions.vue'),
            },
            {
              path: 'chrono-unanswered-marked-assessment',
              name: 'ChronoUnansweredMarkedAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/ChronoAssessment/unanswered-marked.vue'),
            },
            {
              path: 'chrono-feedback-assessment',
              name: 'ChronofeedbackAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/ChronoAssessment/feedback.vue'),
            },
            {
              path: 'chrono-statistics-assessment',
              name: 'ChronoStatisticsAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/ChronoAssessment/statistics.vue'),
            }
          ]
        },
        {
          path: 'rush-steps-assessment',
          name: 'RushStepsAssessment',
          component: () => import('../components/Students/Components/MCQExams/Assessment/RushAssessment/instructions.vue'),
          children: [
            {
              path: 'rush-start-assessment',
              name: 'RushStartAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/RushAssessment/start.vue'),
            },
            {
              path: 'rush-questions-assessment',
              name: 'RushQuestionsAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/RushAssessment/questions.vue'),
            },
            {
              path: 'rush-unanswered-marked-assessment',
              name: 'RushUnansweredMarkedAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/RushAssessment/unanswered-marked.vue'),
            },
            {
              path: 'rush-feedback-assessment',
              name: 'RushfeedbackAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/RushAssessment/feedback.vue'),
            },
            {
              path: 'rush-statistics-assessment',
              name: 'RushStatisticsAssessment',
              component: () => import('../components/Students/Components/MCQExams/Assessment/RushAssessment/statistics.vue'),
            }
          ]
        },

      ]
    },
    {
      path: 'student-zidney-exams',
      name: 'StudentZidneyExams',
      component: () => import('../components/Students/Components/ZidneyExams/List.vue'),
      children: [
        {
          path: 'topics-relax-exams',
          name: 'TopicsRelaxZidenyExams',
          component: () => import('../components/Students/Components/ZidneyExams/Topics/RelaxExams/instructions.vue'),
          children: [
            {
              path: 'topics-relax-start',
              name: 'TopicsRelaxStart',
              component: () => import('../components/Students/Components/ZidneyExams/Topics/RelaxExams/start.vue'),
            },
            {
              path: 'topics-relax-questions',
              name: 'TopicsRelaxQuestions',
              component: () => import('../components/Students/Components/ZidneyExams/Topics/RelaxExams/questions.vue'),
            },
            {
              path: 'topics-relax-unanswered-marked',
              name: 'TopicsRelaxUnansweredMarked',
              component: () => import('../components/Students/Components/ZidneyExams/Topics/RelaxExams/unanswered-marked.vue'),
            },
            {
              path: 'topics-relax-feedback',
              name: 'TopicsRelaxfeedback',
              component: () => import('../components/Students/Components/ZidneyExams/Topics/RelaxExams/feedback.vue'),
            },
            {
              path: 'topics-relax-statistics',
              name: 'TopicsRelaxStatistics',
              component: () => import('../components/Students/Components/ZidneyExams/Topics/RelaxExams/statistics.vue'),
            }
          ]
        },
        {
          path: 'topics-chrono-exams',
          name: 'TopicsChronoZidenyExams',
          component: () => import('../components/Students/Components/ZidneyExams/Topics/ChronoExams/instructions.vue'),
          children: [
            {
              path: 'topics-chrono-start',
              name: 'TopicsChronoStart',
              component: () => import('../components/Students/Components/ZidneyExams/Topics/ChronoExams/start.vue'),
            },
            {
              path: 'topics-chrono-questions',
              name: 'TopicsChronoQuestions',
              component: () => import('../components/Students/Components/ZidneyExams/Topics/ChronoExams/questions.vue'),
            },
            {
              path: 'topics-chrono-unanswered-marked',
              name: 'TopicsChronoUnansweredMarked',
              component: () => import('../components/Students/Components/ZidneyExams/Topics/ChronoExams/unanswered-marked.vue'),
            },
            {
              path: 'topics-chrono-feedback',
              name: 'TopicsChronofeedback',
              component: () => import('../components/Students/Components/ZidneyExams/Topics/ChronoExams/feedback.vue'),
            },
            {
              path: 'topics-chrono-statistics',
              name: 'TopicsChronoStatistics',
              component: () => import('../components/Students/Components/ZidneyExams/Topics/ChronoExams/statistics.vue'),
            }
          ]
        },
        {
          path: 'exercises-relax-exams',
          name: 'ExercisesRelaxZidenyExams',
          component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/instructions.vue'),
          children: [
            {
              path: 'exercises-relax-start',
              name: 'ExercisesRelaxStart',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/start.vue'),
            },
            {
              path: 'exercises-relax-questions',
              name: 'ExercisesRelaxQuestions',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/questions.vue'),
            },
            {
              path: 'exercises-relax-unanswered-marked',
              name: 'ExercisesRelaxUnansweredMarked',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/unanswered-marked.vue'),
            },
            {
              path: 'exercises-relax-feedback',
              name: 'ExercisesRelaxfeedback',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/feedback.vue'),
            },
            {
              path: 'exercises-relax-statistics',
              name: 'ExercisesRelaxStatistics',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/statistics.vue'),
            }
          ]
        },
        {
          path: 'exercises-chrono-exams',
          name: 'ExercisesChronoZidenyExams',
          component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/instructions.vue'),
          children: [
            {
              path: 'exercises-chrono-start',
              name: 'ExercisesChronoStart',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/start.vue'),
            },
            {
              path: 'exercises-chrono-questions',
              name: 'ExercisesChronoQuestions',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/questions.vue'),
            },
            {
              path: 'exercises-chrono-unanswered-marked',
              name: 'ExercisesChronoUnansweredMarked',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/unanswered-marked.vue'),
            },
            {
              path: 'exercises-chrono-feedback',
              name: 'ExercisesChronofeedback',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/feedback.vue'),
            },
            {
              path: 'exercises-chrono-statistics',
              name: 'ExercisesRelaxStatistics',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/statistics.vue'),
            }
          ]
        }, {
          path: 'exercises-relax-exams',
          name: 'ExercisesRelaxZidenyExams',
          component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/instructions.vue'),
          children: [
            {
              path: 'exercises-relax-start',
              name: 'ExercisesRelaxStart',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/start.vue'),
            },
            {
              path: 'exercises-relax-questions',
              name: 'ExercisesRelaxQuestions',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/questions.vue'),
            },
            {
              path: 'exercises-relax-unanswered-marked',
              name: 'ExercisesRelaxUnansweredMarked',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/unanswered-marked.vue'),
            },
            {
              path: 'exercises-relax-feedback',
              name: 'ExercisesRelaxfeedback',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/feedback.vue'),
            },
            {
              path: 'exercises-relax-statistics',
              name: 'ExercisesRelaxStatistics',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/RelaxExams/statistics.vue'),
            }
          ]
        },
        {
          path: 'exercises-chrono-exams',
          name: 'ExercisesChronoZidenyExams',
          component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/instructions.vue'),
          children: [
            {
              path: 'exercises-chrono-start',
              name: 'ExercisesChronoStart',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/start.vue'),
            },
            {
              path: 'exercises-chrono-questions',
              name: 'ExercisesChronoQuestions',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/questions.vue'),
            },
            {
              path: 'exercises-chrono-unanswered-marked',
              name: 'ExercisesChronoUnansweredMarked',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/unanswered-marked.vue'),
            },
            {
              path: 'exercises-chrono-feedback',
              name: 'ExercisesChronofeedback',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/feedback.vue'),
            },
            {
              path: 'exercises-chrono-statistics',
              name: 'ExercisesRelaxStatistics',
              component: () => import('../components/Students/Components/ZidneyExams/Exercises/ChronoExams/statistics.vue'),
            }
          ]
        },
        {
          path: 'path-full-zidney-exams',
          name: 'ScheduledZidneyExams',
          component: () => import('../components/Students/Components/ZidneyExams/ScheduledExams/instructions.vue'),
          children: [
            {
              path: 'scheduled-zidney-start',
              name: 'ScheduledZidneyStart',
              component: () => import('../components/Students/Components/ZidneyExams/ScheduledExams/start.vue'),
            },
            {
              path: 'scheduled-zidney-questions',
              name: 'ScheduledZidneyQuestions',
              component: () => import('../components/Students/Components/ZidneyExams/ScheduledExams/questions.vue'),
            },
            {
              path: 'scheduled-zidney-unanswered-marked',
              name: 'ScheduledZidneyUnansweredMarked',
              component: () => import('../components/Students/Components/ZidneyExams/ScheduledExams/unanswered-marked.vue'),
            },
            {
              path: 'scheduled-zidney-feedback',
              name: 'ScheduledZidneyfeedback',
              component: () => import('../components/Students/Components/ZidneyExams/ScheduledExams/feedback.vue'),
            },
            {
              path: 'scheduled-zidney-statistics',
              name: 'ScheduledZidneyStatistics',
              component: () => import('../components/Students/Components/ZidneyExams/ScheduledExams/statistics.vue'),
            }
          ]
        },

      ]
    },
    {
      path: 'library',
      name: 'LibraryStudent',
      component: () => import('../components/Students/Components/Library/List.vue'),
      children: [
        {
          path: 'item',
          name: 'LibraryStudentItem',
          component: () => import('../components/Students/Components/Library/item.vue'),

          children: [
            {
              path: 'sub-item',
              name: 'LibraryStudentSubItem',
              component: () => import('../components/Students/Components/Library/subItem.vue')
            },
          ]
        },
        
      ]
    },
    {
      path: 'learning-paths',
      name: 'LearningPaths',
      component: () => import('../components/Students/Components/LearningPaths/List.vue'),
      children: [
        {
          path: 'resume',
          name: 'LearningPathsResume',
          component: () => import('../components/Students/Components/LearningPaths/resume.vue'),
        },
        {
          path: 'create-path',
          name: 'LearningPathsCreatePath',
          component: () => import('../components/Students/Components/LearningPaths/createPath.vue')
        },
        {
          path: 'show-subject',
          name: 'LearningPathsShowSubject',
          component: () => import('../components/Students/Components/LearningPaths/show-subject.vue'),
        }
        
      ]
    },
     {
      path: 'libraries',
      name: 'librariesStudent',
      component: () => import('../components/Students/Components/Libraries/List.vue'),
      children: [
        {
          path: 'libraries-item',
          name: 'librariesStudentItem',
          component: () => import('../components/Students/Components/Libraries/item.vue')
        },        {
          path: 'libraries-subitem',
          name: 'librariesStudentSubItem',
          component: () => import('../components/Students/Components/Libraries/subItem.vue')
        },
      ]
    },  {
      path: 'lives-student',
      name: 'LivesStudent',
      component: () => import('../components/Students/Components/Lives/List.vue'),
      
    },
    {
      path: 'learning-path',
      name: 'LearningPath',
      component: () => import('../components/Students/Components/LearningPath/List.vue'),
      children: [
        {
          path: 'path-resume',
          name: 'LearningPathResume',
          component: () => import('../components/Students/Components/LearningPath/resume.vue'),
        },
        {
          path: 'create-learning-path',
          name: 'CreateLearningPath',
          component: () => import('../components/Students/Components/LearningPath/create.vue')
        },{
          path: 'path-show-subject',
          name: 'PathShowSubject',
          component: () => import('../components/Students/Components/LearningPath/showSubject.vue'),
        },{
          path: 'show-your-path',
          name: 'ShowYourPath',
          component: () => import('../components/Students/Components/LearningPath/showYourPath.vue'),
        },
        {
          path: 'path-full-exam',
          name: 'PathFullStartInstructions',
          component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledFullExams/instructions.vue'),
          children: [
            {
              path: 'path-full-start',
              name: 'PathFullStart',
              component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledFullExams/start.vue'),
            },
            {
              path: 'path-full-questions',
              name: 'PathFullQuestions',
              component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledFullExams/questions.vue'),
            },
            {
              path: 'path-full-unanswered-marked',
              name: 'PathFullUnansweredMarked',
              component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledFullExams/unanswered-marked.vue'),
            },
            {
              path: 'path-full-feedback',
              name: 'PathFullfeedback',
              component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledFullExams/feedback.vue'),
            },
            {
              path: 'path-full-statistics',
              name: 'PathFullStatistics',
              component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledFullExams/statistics.vue'),
            }
          ]
        },{
          path: 'path-mcq-exam',
          name: 'PathMCQInstructions',
          component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledMQExams/instructions.vue'),
          children: [
            {
              path: 'path-mcq-start',
              name: 'PathMCQStart',
              component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledMQExams/start.vue'),
            },
            {
              path: 'path-mcq-questions',
              name: 'PathMCQQuestions',
              component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledMQExams/questions.vue'),
            },
            {
              path: 'path-mcq-unanswered-marked',
              name: 'PathMCQUnansweredMarked',
              component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledMQExams/unanswered-marked.vue'),
            },
            {
              path: 'path-mcq-feedback',
              name: 'PathMCQfeedback',
              component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledMQExams/feedback.vue'),
            },
            {
              path: 'path-mcq-statistics',
              name: 'PathMCQStatistics',
              component: () => import('../components/Students/Components/LearningPath/Exams/ScheduledMQExams/statistics.vue'),
            }
          ]
        },
        
      ]
    },


  ]
},)


router.beforeResolve((to, from, next) => {

  let schoolName  =to.params.schoolName;
  axios.defaults.params = {}
  axios.defaults.params['system'] = schoolName;
  store.commit('auth/SET_ROUTE', to);

  const publicPages = ['Login','InvalidLicense'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = store.getters['auth/authenticated'];
  const user = store.getters['auth/user'];

  const lacksRole = to.matched.some(route => {
    return route.meta.role == 'admin'
  })
      console.log(to.params.schoolName);
  console.log(localStorage.getItem('schoolName'))

  if(to.params.schoolName != localStorage.getItem('schoolName')) {
    if(localStorage.getItem('tokens')) {
      let tokens = JSON.parse(localStorage.getItem('tokens'));
      let index = tokens.findIndex(record => record.schoolName === to.params.schoolName);
      if (index == -1) {
        console.log("toooooooooooken","yasooooooooooooooo");

        store.dispatch('auth/logout');
      }else {
        store.commit('auth/SET_TOKEN', tokens[index].token);
        localStorage.setItem('role',tokens[index].role)
        console.log("toooooooooooken",tokens[index].token);
        localStorage.setItem('token',tokens[index].token)
        localStorage.setItem('expirationDate',tokens[index].expirationDate)
        localStorage.setItem('storageName',tokens[index].storageName)
        window.location = "/"+to.params.schoolName;
      }
    }
  }


  localStorage.setItem('schoolName',schoolName);

  // if (lacksRole && user.role_id >= 1) {
  //   return next('/');
  // }




  if (authRequired && !loggedIn) {
    // alert(loggedIn)
    return next('/'+schoolName+'/login');
  }
  if (loggedIn && !authRequired) {
    return next('/'+schoolName);
  }

  if (lacksRole && user.role_id == null) {
    return next('/'+schoolName+'/student');
  }

  if (to.path == '/'+schoolName && user.role_id == null) {
    return next('/'+schoolName+'/student');
  }

  console.log('to.name:', to.name)

  // if(authRequired && loggedIn && to.path !== '/'   && to.meta.type !== 'none'   && user.role_id != null) {
  //   let user = store.getters["auth/user"];
  //   let permissions = store.getters["auth/permissions"];
  //
  //   if (user.role_id == 1 || (permissions.findIndex(p => p.name === to.meta.permission ) != -1 && to.meta.type == 'child') ||
  //       ( permissions.findIndex(p => p.model_name === to.meta.permission ) != -1  && to.meta.type == 'parent'  )
  //
  //   ) {
  //     next();
  //   }else {
  //     return next('/');
  //
  //   }
  // }

  next();
})

export default router